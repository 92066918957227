import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/SEO'

import { Grid, Container } from '@material-ui/core'

const NotFound = ({ pageContext: { locale }, location }) => {
  return (
    <>
      <Container maxWidth='lg'>
        <main>
          {/* <SEO pathname={location.pathname} locale={locale} /> */}
          <div className='container margin_120'>
            <div className='row'></div>
            <h1>Pagina nu a fost găsită</h1>
            <p>Ne pare rău, dar pagina accesata nu a fost găsită!</p>
          </div>
        </main>
      </Container>
    </>
  )
}
export default NotFound

NotFound.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
